<template>
  <div class="serviceDetail">
    <van-nav-bar
      title="售后详情"
      left-arrow
      v-if="xcx != 1"
      @click-left="onClickLeft"
    />
    <van-notice-bar
      left-icon="volume-o"
      wrapable
      v-if="order.status==1"
      :scrollable="false"
      text="订单已进入仓库发货，请您耐心等待，取消已发出订单将产生物流费用"
    />
    <div class="order">
      <van-cell :border="false" class="order_sn">
        <template #title>
          <div @click="copy(order.ordersn)">
            {{order.ordersn}}
            <van-button size="mini">复制</van-button>
          </div>
        </template>
        <template #right-icon>
          <div>
            <div v-if="order.status == 1 ? true : false">
              {{order.is_outorder==1 ? '已提单，' : '未提单，'}}{{order.status_str}}
            </div>
            <div v-if="order.status > 1 ? true : false">
              {{order.status_str}}
            </div>
          </div>
        </template>
      </van-cell>
      <div class="goods_list">
        <van-card
          v-for="(item, index) in order.goods"
          :key="index"
          :desc="item.optionname"
          :title="item.goodstitle"
          :thumb="item.goodsthumb"
        />
      </div>
    </div>

    <div class="input_service">
      <van-field
        v-model="message"
        rows="3"
        type="textarea"
        :placeholder="(rtype == 1 || rtype == 2 || rtype == 3) && progress == 3 ?'请输入寄回单号':'请输入售后信息'"
      />
      <van-field
        v-model="customerneed"
        rows="3"
        type="textarea"
        :placeholder="'请填写您的售后诉求'"
      />
      <van-cell class="img_service">
        <template #title>
          <div>
            快递面单：
          </div>
        </template>
        <template #label>
          <van-uploader
            v-model="fileList1"
            multiple
            :after-read="afterRead1"
            @delete="deleteImg1"
          />
        </template>
      </van-cell>
      <van-cell class="img_service">
        <template #title>
          <div>
            箱型图片（外箱全图）：
          </div>
        </template>
        <template #label>
          <van-uploader
            v-model="fileList2"
            multiple
            :after-read="afterRead2"
            @delete="deleteImg2"
          />
        </template>
      </van-cell>
      <van-cell class="img_service">
        <template #title>
          <div>
            产品损坏图片：
          </div>
        </template>
        <template #label>
          <van-uploader
            v-model="fileList"
            multiple
            :after-read="afterRead"
            @delete="deleteImg"
          />
        </template>
      </van-cell>
      <van-cell class="img_service">
        <template #title>
          <div>
            上传视频：
          </div>
        </template>
        <template #label>
          <van-uploader v-model="filetwoList" accept="video" :after-read="afterReadVideo" :delete="afterDdeleteVideo" max-count="1" />
        </template>
      </van-cell>
      <div class="btn_list">
        <van-button size="small" plain round :loading="isdeabled" @click="addService">提交</van-button>
        <van-button size="small" plain round :loading="isdeabled" @click="cancelService">{{rtype == 0 ? '取消退款': '取消售后'}}</van-button>
      </div>
    </div>

    <div class="service">
      <van-cell center class="service_title">
        <template #title>
          <div>
            售后记录（{{refund.status_str}}）
          </div>
        </template>
        <template #right-icon>
          <div v-if="false"> <!-- list && list.length > 0 && list[0].progress && list[0].progress == 3 -->
            <van-button @click="showInput" size="mini">添加单号</van-button>
          </div>
        </template>
      </van-cell>
      <div class="service_list" v-if="list && list.length > 0">
        <van-cell v-for="(item, index) in list" :key="index">
          <template #title>
            <div>{{item.text}}</div>
            <div style="display:block;overflow:hidden;" v-if="(item.video && item.video.length > 10) || (item['sh_img'] && item['sh_img'].length > 0)">
              <video
                v-if="item.video && item.video.length > 10"
                :src="item.video"
                style="width: 50px; height: 50px; float: left;margin: 0 10px 10px 0;"
                controls
              ></video>
              <div style="display: inline-block;vertical-align: top;" v-if="item['sh_img'] && item['sh_img'].length > 0">
                <van-image
                  v-for="(val, key) in item['sh_img']"
                  :key="key"
                  :src="val"
                  lazy-load
                  style="width: 50px; height: 50px; float: left;margin: 0 10px 10px 0;"
                  fit="cover"
                  @click="yulan(item['sh_img'], key)"
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
              </div>
              <van-button plain size="small" v-if="item.video && item.video.length > 10" @click="dowloadVideo(item.video)">下载视频</van-button>
            </div>
          </template>
          <template #label>
            <div>{{item.desc}}</div>
          </template>
        </van-cell>
      </div>
    </div>

    <van-dialog
      v-model="showAddinfo"
      title="退回商品"
      show-cancel-button
      @confirm="btnConfirm"
      @cancel="btnCancel"
    >
      <div class="input_main">
        <van-field
          :value="rexpresssn"
          placeholder="请输入退货单号"
          :border="false"
          @input="onChangeInput"
          input-align="center"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  name: "serviceDetail",
  data(){
    return {
      merchid: localStorage.getItem('SHOP_MID') ? localStorage.getItem('SHOP_MID') : 0,
      id: 0,
      order: {}, // 订单信息
      list: [], // 售后信息
      refund: {}, // 退款售后信息
      showAddinfo: false, // 显示输入单号信息弹框
      rexpresssn: "", // 退回单号
      addressTong: 1,
      addressInfo: {}, // 新增地址信息
      message: "", // 用户输入的售后信息
      customerneed: "",
      rtype: '',
      xcx: 0,
      progress: 0,
      isdeabled: false, // 是否禁用提交按钮
      fileList: [], // 上传图片列表合集
      uploadImg: [], // 获取上传列表
      fileList1: [], // 上传图片列表合集
      uploadImg1: [], // 获取上传列表
      fileList2: [], // 上传图片列表合集
      uploadImg2: [], // 获取上传列表
      video: '',
      filetwoList: [],
      imgIndex: -1, // 索引
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.rtype = this.$route.query.rtype
    this.xcx = this.$route.query.xcx ? parseInt(this.$route.query.xcx) : 0;
    this.init();
  },
  methods: {
    init() {
      this.getorder(); // 获取订单信息
      this.getlist(); // 获取售后信息
      this.getlastService(); // 获取最后一次售后信息
    },

    onClickLeft() {
      this.$router.back();
    },

    getorder() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "shopsmobile/order/detail",
          _that.$qs.stringify({
            id: _that.id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.order = res.data.data;
          }
        })
        .catch(err => {
          console.log(err, '获取订单信息失败');
        })
    },

    // 获取最后一条售后信息
    getlastService() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/service_last",
          _that.$qs.stringify({
            id: _that.id
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            if (res.data.data.status > 0 && res.data.data.status != 2) {
              _that.rtype = res.data.data.rtype
              _that.progress = res.data.data.progress
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    
    getlist() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/service_list",
          _that.$qs.stringify({
            id: _that.id
          })
        )
        .then(res => {
          console.log(res, "_that.list");
          if (res.data.code == 100000) {
            _that.list = res.data.data.list;
            
            console.log(_that.rtype, _that.progress);
            _that.refund = res.data.data.refund;
          }
        })
        .catch(err => {
          console.log(err, '获取订单信息失败');
        })
    },

    /**
     * 复制订单号
     */
    copy(ordersn) {
      if (ordersn) {
        let _that = null;
        _that = this;
        _that.$copyText(ordersn).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
      }
    },
    /**
     * 点击输入单号按钮
     */
    showInput() {
      this.showAddinfo = true;
    },

    // 点击确认按钮
    btnConfirm() {
      console.log(this.rexpresssn, '确认');
      let _that = null;
      _that = this;
      _that.$toast({
        type: "loading",
        message: "加载中..."
      })
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/add_rexpress",
          _that.$qs.stringify({
            express: _that.rexpresssn,
            id: _that.order.id
          })
        )
        .then(res => {
          console.log(res);
          _that.$toast.clear();
          if (res.data.code == 100000) {
            _that.$toast(res.data.msg ? res.data.msg : "添加成功");
            setTimeout(() => {
              _that.onClickLeft();
            }, 1500);
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "添加失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 点击取消按钮
    btnCancel() {
      console.log(this.rexpresssn, '取消');
      this.showAddinfo = false;
    },

    // 获取输入内容
    onChangeInput(e) {
      this.rexpresssn = e;
    },

    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          })
      }
    },

    // 预览
    yulan(imgArr, index){
      ImagePreview({
        images: imgArr,
        startPosition: index,
        closeable: true,
      });
    },

    // 添加售后
    addService () {
      let _that = null;
      _that = this;
      
      _that.isdeabled = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
        _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/order/service_add",
            _that.$qs.stringify({
              orderid: _that.id,
              uploader: _that.uploadImg,
              uploader1: _that.uploadImg1,
              uploader2: _that.uploadImg2,
              customerneed: _that.customerneed,
              videoSrc: _that.video,
              message: _that.message,
              rtype: _that.rtype,
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.$toast(res.data.msg ? res.data.msg : '提交成功')
              console.log(res.data.msg);
              _that.message = "";
              _that.getlist();
            }else{
              _that.$toast(res.data.msg ? res.data.msg : '提交失败') 
            }
            _that.isdeabled = false;
          })
          .catch(error => {
            console.log(error);
            _that.isdeabled = false;
          })
      
      console.log("添加售后");
    },
    // 取消售后
    cancelService () {
      console.log("取消售后");
      let _that = null;
      _that = this;
      _that.isdeabled = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID")
        _that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/order/cancel_refund",
            _that.$qs.stringify({
              orderid: _that.id,
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.$toast(res.data.msg ? res.data.msg : '取消售后成功')
              _that.$router.back();
            }else{
              _that.$toast(res.data.msg ? res.data.msg : '取消售后失败') 
            }
            _that.isdeabled = false;
          })
          .catch(error => {
            console.log(error);
            _that.isdeabled = false;
          })
    },

    afterRead(file) {
      // 开始上传文件
      console.log(file);
      let types = null;
      types = this.isType2(file);
      if (types == "Array") {
        file.forEach(res => {
          this.uploaderFile(res);
        })
      }else{
        this.uploaderFile(file);
      }
    },
    uploaderFile(file) {
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/tuikuan/');
      fordata.append('merchid',_that.merchid);

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data"
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data.code == 100000) {
          _that.uploadImg.push(response.data.img_url);
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 执行删除图片
    deleteImg(file, detail) {
      this.imgIndex = detail.index;
      this.uploadImg.splice(this.imgIndex, 1);
      console.log(this.uploadImg);
    },

    afterRead1(file) {
      // 开始上传文件
      console.log(file);
      let types = null;
      types = this.isType2(file);
      if (types == "Array") {
        file.forEach(res => {
          this.uploaderFile1(res);
        })
      }else{
        this.uploaderFile1(file);
      }
    },
    uploaderFile1(file) {
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/tuikuan/');
      fordata.append('merchid',_that.merchid);

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data"
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data.code == 100000) {
          _that.uploadImg1.push(response.data.img_url);
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 执行删除图片
    deleteImg1(file, detail) {
      this.imgIndex = detail.index;
      this.uploadImg1.splice(this.imgIndex, 1);
      console.log(this.uploadImg1);
    },

    afterRead2(file) {
      // 开始上传文件
      console.log(file);
      let types = null;
      types = this.isType2(file);
      if (types == "Array") {
        file.forEach(res => {
          this.uploaderFile2(res);
        })
      }else{
        this.uploaderFile2(file);
      }
    },
    uploaderFile2(file) {
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/tuikuan/');
      fordata.append('merchid',_that.merchid);

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data"
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data.code == 100000) {
          // const obj = {
          //   url: response.data.img_url
          // }
          // _that.uploader.push(obj);
          _that.uploadImg2.push(response.data.img_url);
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 执行删除图片
    deleteImg2(file, detail) {
      this.imgIndex = detail.index;
      this.uploadImg2.splice(this.imgIndex, 1);
      console.log(this.uploadImg2);
    },
    afterReadVideo(file){
      let _that = null;
      _that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/video/');
      fordata.append('merchid',_that.merchid);

      _that.$axios.defaults.headers["Content-Type"] = "multipart/form-data"
      _that.$axios.post(
        "https://api.midbest.cn/admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data.code == 100000) {
          _that.video = response.data.img_url;
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    afterDdeleteVideo(file, detail){
      const index = detail.index;
      this.data.filetwoList.splice(index, 1);
      this.data.video = '';
    },
    // 判断变量类型
    isType2(type) {
      return (/^\[object\s(.*)\]$/.exec(Object.prototype.toString.call(type)))[1];
    },
    
    // 下载视频
    dowloadVideo(url) {
      window.location.href = url;
    },

  }
};

</script>

<style lang="less">
.serviceDetail {
  text-align: left;
  min-height: 100%;
  background-color: #ededed;
  overflow: hidden;
  padding: 0;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .order {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    .order_sn {
      .van-cell__title {
        flex: 1;
      }
    }
    .goods_list {
      .van-card {
        background-color: #fff;
        margin-top: 0;
      }
    }
  }
  .service {
    margin-top: 10px;
    overflow: hidden;
    .service_title {
      .van-cell__title {
        flex: 1;
      }
    }
  }
  .input_main {
    margin: 10px 16px 20px;
    overflow: hidden;
    .van-cell__value {
      flex: 1;
      .van-field__body { 
        flex: 1;
      }
    }
  }
  .input_service {
    margin: 10px;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
    .van-cell {
      .van-cell__value {
        flex: 1;
      }
    }
    .img_service {
      .van-cell__value {
        flex: 1;
        text-align: left;
      }
    }
    .btn_list {
      margin: 10px;
      text-align: center;
      .van-button {
        margin-right: 20px;
        padding: 0 20px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .service {
    padding-bottom: 60px;
  }
}
</style>
